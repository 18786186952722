/* eslint-disable */

/*
 * Search Toggle
 *
 * runs the functionality of clicking a search icon and a search bar appears
 */

const selector = '.m-search-bar';

const tabbableElements = $(
    `${selector} a[href]:visible:not([disabled]), 
     ${selector} button:visible:not([disabled]),
     ${selector} input:visible:not([disabled]), 
     ${selector} select:visible:not([disabled]), 
     ${selector} textarea:visible:not([disabled]), 
     ${selector} [tabindex]:not([tabindex="-1"]):visible:not([disabled])`,
);

function searchBar() {
    // Set tabindex -1 for tabbable elements by default
    tabbableElements.attr('tabindex', '-1');

    // Search Toggle
    $('.js-header-search-toggle').on('click keydown', function (e) {
        // Check if the event is a keydown and if the key is not Enter, return
        if (e.type === 'keydown' && e.which !== 13) {
            return;
        }

        $(this).toggleClass('a-button-search--active');
        $('.m-search-bar').toggleClass('m-search-bar--active');
        $('body').toggleClass('h-scroll-lock');

        if ($('.algolia-autocomplete').css('display') == 'block') {
            $('.algolia-autocomplete').css('display', 'none');
        }

        // If the search bar has the active class
        if ($('.m-search-bar').hasClass('m-search-bar--active')) {
            // Set all tabbable elements' tabindex to 0
            tabbableElements.attr('tabindex', '0');
            setTimeout(function () {
                $('.m-search-bar__search-field').focus();
            }, 600);

            // If the search bar does not have the active class
        } else {
            // Set all tabbable elements' tabindex to -1
            tabbableElements.attr('tabindex', '-1');
        }
    });

    // Handle Shift + Tab from the search field to go back to the toggle button
    $('.m-search-bar__search-field').on('keydown', function (e) {
        if (e.which === 9 && e.shiftKey) {
            // 9 is the key code for Tab
            e.preventDefault(); // Prevent the default shifting behavior
            $('.js-header-search-toggle').focus(); // Shift focus to the toggle button
        }
    });

    // Ensuring focus shifts back to search toggle button after last link in search bar
    tabbableElements.last().on('keydown', function (e) {
        if (e.which === 9 && !e.shiftKey) {
            // 9 is the key code for Tab
            e.preventDefault(); // Prevent the default shifting behavior
            $('.js-header-search-toggle').focus(); // Shift focus to the toggle button
        }
    });

    $('.js-header-search-toggle-mobile').on('click', function () {
        $('.js-slide-out-search-wrapper-mob').toggleClass(
            'c-slide-out__search-wrapper--active',
        );
        setTimeout(function () {
            $('.js-search-field-mob').focus();
        }, 600);
    });

    $('.m-search-bar__search-field').keyup(function (e) {
        if ($(this).val().length) {
            $('.m-popular-searches').css('display', 'none');
        } else {
            $('.m-popular-searches').css('display', 'block');
        }
    });

    $('.m-search-bar__search-field').keydown(function (e) {
        if (e.key === 'Enter') {
            e.preventDefault(); // Stop form submission
            return false; // Prevent the default form submit behavior
        }
        return true; // Allow other keys to function normally
    });
}

export default searchBar;
